<template>
  <div class="burger-menu container">
    <div class="burger-menu__header">
      <div class="burger-menu__logo">
        <div class="burger is-active">
          <span class="burger-line burger-top"></span>
          <span class="burger-line burger-middle"></span>
          <span class="burger-line burger-bottom"></span>
        </div>
        <a href="#">
          <svg
            width="200"
            height="51"
            viewBox="0 0 200 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.3702 0.55331L16.6436 4.37788H16.7544C18.343 1.38701 20.5856 0 23.434 0C27.0472 0 28.9757 1.49543 30.2244 4.37788H30.3316C32.5778 0.886044 35.2046 0 37.5062 0C44.3336 0 45.6636 4.76669 45.6636 9.69414V26.5963H36.904V11.7466C36.904 9.36515 37.0112 7.03601 34.4952 7.03601C31.8648 7.03601 31.4288 9.14083 31.4288 11.5784V26.5963H22.6656V11.7466C22.6656 9.36515 22.7764 7.03601 20.2568 7.03601C17.63 7.03601 17.1904 9.14083 17.1904 11.5784V26.5963H8.54161V10.969C6.35079 11.0251 3.99741 9.75022 4.10825 6.42662C1.64404 6.26212 0 4.54238 0 1.33093V0.55331H16.3702Z"
              fill="#F50F64"
            />
            <path
              d="M56.8948 27.0374C52.7348 27.0374 48.682 25.1681 48.682 19.392C48.682 9.03241 64.014 14.0197 64.014 8.4791C64.014 7.20425 63.2456 6.42662 61.2764 6.42662C59.3073 6.42662 57.9921 7.58932 57.5524 10.0269L49.7238 8.75576C51.0797 2.61701 55.7347 0 61.5942 0C68.3477 0 72.781 2.21698 72.781 9.6418V17.119C72.781 17.9527 72.9473 24.5438 73.1098 26.5963H65.2628L64.934 23.7138H64.8231C62.9759 26.043 60.1681 27.0374 56.8837 27.0374H56.8948ZM57.6522 18.506C57.6522 20.0575 58.7605 20.8875 60.2789 20.8875C62.7431 20.8875 64.1101 19.2799 64.1101 16.775V14.6814C61.8121 16.5096 57.6522 15.459 57.6522 18.506Z"
              fill="#F50F64"
            />
            <path
              d="M88.6931 29.9759C91.4861 29.9759 92.9085 28.8132 92.9085 25.4896V22.4988H92.8013C91.4861 24.3269 88.9111 25.714 86.0663 25.714C79.3313 25.714 76.1541 20.2818 76.1541 12.9131C76.1541 5.65274 79.7709 0 86.4505 0C89.4061 0 91.8149 1.82817 93.0747 3.43576H93.1819L93.3481 0.55331H101.672V22.7156C101.672 32.4696 97.1793 36.4026 88.5859 36.4026C83.8755 36.4026 79.3313 34.7389 77.1331 29.1983L84.522 27.329C85.2868 29.1572 86.4912 29.9909 88.682 29.9909L88.6931 29.9759ZM93.1708 12.9804C93.1708 8.86792 91.8592 6.83039 89.1771 6.83039C86.4407 6.83039 85.0713 8.88038 85.0688 12.9804C85.0688 17.2461 86.4395 19.2948 89.1771 19.2948C91.8592 19.2948 93.1708 17.2461 93.1708 12.9804Z"
              fill="#F50F64"
            />
            <path
              d="M122.531 0C128.608 0 130.045 4.65453 130.045 10.0942V26.5963H121.271V11.8027C121.271 9.41749 121.16 7.03601 118.204 7.03601C115.411 7.03601 114.809 9.41749 114.809 11.8588V26.5963H106.05V0.55331H114.37L114.591 4.20964H114.698C116.834 1.33094 119.298 0 122.531 0Z"
              fill="#F50F64"
            />
            <path
              d="M141.801 27.1496C135.831 27.1496 134.412 22.495 134.412 17.0554V0.55331H143.172V15.3469C143.172 17.7284 143.283 20.1136 146.127 20.1136C148.813 20.1136 149.415 17.7284 149.415 15.2908V0.55331H158.175V26.5963H149.848L149.626 22.9399H149.515C147.435 25.8186 144.971 27.1496 141.797 27.1496"
              fill="#F50F64"
            />
            <path
              d="M170.433 0.55331L170.707 4.37788H170.817C172.402 1.38701 174.748 0 177.604 0C181.273 0 183.19 1.49543 184.45 4.37788H184.557C186.803 0.886044 189.486 0 191.787 0C198.685 0 200 4.76669 200 9.69414V26.5963H191.24V11.7466C191.24 9.36515 191.348 7.03601 188.776 7.03601C186.094 7.03601 185.654 9.14083 185.654 11.5784V26.5963H176.895V11.7466C176.895 9.36515 177.002 7.03601 174.431 7.03601C171.748 7.03601 171.309 9.14083 171.309 11.5784V26.5963H162.557V0.55331H170.433Z"
              fill="#F50F64"
            />
            <path
              d="M134.9 38.0043C134.798 42.8203 138.903 46.821 143.794 46.7191C147.012 46.7191 149.748 45.1647 151.193 42.54L148.608 40.7054C147.417 42.591 145.821 43.5338 143.819 43.5338C140.702 43.5338 138.422 41.2405 138.422 38.0298C138.422 34.8191 140.702 32.4747 143.819 32.4747C145.821 32.4747 147.417 33.4176 148.608 35.3032L151.193 33.4685C149.698 30.8439 146.986 29.2895 143.819 29.2895C138.954 29.1876 134.824 33.1882 134.9 38.0043Z"
              fill="#F50F64"
            />
            <path
              d="M152.915 40.0938C152.839 43.6867 155.93 46.77 159.604 46.6936C163.279 46.77 166.446 43.6867 166.37 40.0938C166.446 36.5009 163.304 33.4685 159.63 33.5195C155.956 33.443 152.839 36.5009 152.915 40.0938ZM162.873 40.1193C162.873 42.0814 161.48 43.5338 159.63 43.5338C157.729 43.5338 156.386 42.0814 156.386 40.1193C156.386 38.1062 157.729 36.6537 159.63 36.6537C161.48 36.6537 162.873 38.1572 162.873 40.1193Z"
              fill="#F50F64"
            />
            <path
              d="M176.744 33.7998C176.415 33.6979 176.06 33.6469 175.629 33.6469C174.235 33.6469 173.019 34.2585 172.183 35.3032V33.7998H169.041V46.4133H172.462V40.1193C172.462 37.9533 173.779 36.8321 175.832 36.8321H176.744V33.7998Z"
              fill="#F50F64"
            />
            <path
              d="M192.117 40.0938C192.117 36.3225 189.533 33.5195 186.036 33.5195C184.313 33.5195 182.843 34.182 182.007 35.3797V33.7998H178.865V51H182.286V45.1901C183.198 46.1839 184.515 46.7191 186.036 46.7191C189.533 46.7191 192.117 43.8651 192.117 40.0938ZM188.646 40.1193C188.646 42.1833 187.227 43.5848 185.402 43.5848C183.654 43.5848 182.235 42.3362 182.235 40.0938C182.235 37.8259 183.679 36.6283 185.402 36.6283C187.252 36.6283 188.646 38.0043 188.646 40.1193Z"
              fill="#F50F64"
            />
            <path
              d="M194.047 44.3492C194.047 45.6998 195.085 46.7191 196.428 46.7191C197.771 46.7191 198.76 45.6998 198.76 44.3492C198.76 43.0242 197.746 41.9794 196.428 41.9794C195.085 41.9794 194.047 43.0242 194.047 44.3492Z"
              fill="#F50F64"
            />
          </svg>
        </a>
      </div>
      <!-- nav -->
      <div class="header-top__nav">
        <div class="header-top__settings header-top__nav-item">
          <div class="header-top__address">
            <svg
              width="15"
              height="19"
              viewBox="0 0 15 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49996 19C6.14668 17.863 4.89232 16.6165 3.74998 15.2736C2.03571 13.2569 9.46776e-07 10.2534 9.46776e-07 7.39128C-0.0015185 4.40209 1.82597 1.70659 4.62969 0.562611C7.43342 -0.581368 10.6607 0.0516618 12.8056 2.16631C14.2161 3.54944 15.0063 5.43088 15 7.39128C15 10.2534 12.9642 13.2569 11.2499 15.2736C10.1076 16.6165 8.85324 17.863 7.49996 19ZM7.49996 2.11459C4.54278 2.11808 2.14639 4.47849 2.14285 7.39128C2.14285 8.62181 2.70749 10.7525 5.39462 13.9196C6.0569 14.6985 6.75962 15.4432 7.49996 16.1506C8.24036 15.444 8.94343 14.7004 9.60638 13.9228C12.2924 10.7515 12.8571 8.62075 12.8571 7.39128C12.8535 4.47849 10.4571 2.11808 7.49996 2.11459ZM7.49996 10.5573C5.72477 10.5573 4.28569 9.13982 4.28569 7.39128C4.28569 5.64274 5.72477 4.22527 7.49996 4.22527C9.27516 4.22527 10.7142 5.64274 10.7142 7.39128C10.7142 8.23096 10.3756 9.03625 9.7728 9.62999C9.17 10.2237 8.35244 10.5573 7.49996 10.5573Z"
                fill="#F50F64"
              />
            </svg>
            Алм
          </div>
          <div class="header-top__language">ru</div>
        </div>
      </div>
    </div>

    <ul class="burger-menu__list">
      <li class="burger-menu__item">
        <locale-router-link to="/suppliers" class="burger-menu__link"
          >Поставщикам</locale-router-link
        >
      </li>
      <li class="burger-menu__item">
        <locale-router-link to="/advertisers" class="burger-menu__link"
          >Рекламодателям</locale-router-link
        >
      </li>
      <li class="burger-menu__item">
        <locale-router-link to="/tenants" class="burger-menu__link"
          >Арендаторам</locale-router-link
        >
      </li>
      <li class="burger-menu__item">
        <locale-router-link to="/landlords" class="burger-menu__link"
          >Арендодателям</locale-router-link
        >
      </li>
      <li class="burger-menu__item">
        <locale-router-link to="/bidding" class="burger-menu__link"
          >Торги в Magnum</locale-router-link
        >
      </li>
      <li class="burger-menu__item">
        <locale-router-link to="/magnum-opt" class="burger-menu__link"
          >Magnum opt</locale-router-link
        >
      </li>
      <li class="burger-menu__item">
        <locale-router-link to="/compliance-service" class="burger-menu__link"
          >Служба Комплаенс</locale-router-link
        >
      </li>
    </ul>

    <!-- Info -->
    <div class="footer__info footer-info">
      <div class="footer-info__support">Служба поддержки</div>
      <div class="footer-info__number">
        <a href="tel:7766" class="support-number">7766</a>
        <span>звонок бесплатный</span>
      </div>
      <div class="footer-info__info">Офциальный сайт Magnum</div>
      <a href="https://magnum.kz/ru" target="_blank" class="footer-info__link">
        <span>Magnum.kz</span>
        <svg
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6667 10.8365H1.33333V1.63527H6V0.320801H1.33333C0.593333 0.320801 0 0.912311 0 1.63527V10.8365C0 11.5595 0.593333 12.151 1.33333 12.151H10.6667C11.4 12.151 12 11.5595 12 10.8365V6.2359H10.6667V10.8365ZM7.33333 0.320801V1.63527H9.72667L3.17333 8.09588L4.11333 9.02258L10.6667 2.56197V4.92144H12V0.320801H7.33333Z"
            fill="#F50F64"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BurgerMenu",
};
</script>
